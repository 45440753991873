/* style
-------------------------------------------------- */
#page-top a {
  display: block;
  z-index: 9999;
  position: fixed;
  right: 30px;
  bottom: 30px;
  cursor: pointer;
  background: #1C8A34;
  text-align: center;
  padding: 7px 12px 7px 25px;
  border-radius: 5px;
  opacity: 1;
  transition-duration: .6s;
  transition-property: opacity; }
  #page-top a:before {
    content: "";
    position: absolute;
    top: 23px;
    left: 7px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 12px 8px;
    border-color: transparent transparent #fff transparent; }
  #page-top a:hover {
    opacity: .6; }

header {
  background: #D4EDDA; }

.bg-main {
  color: #fff;
  background: #1C8A34; }
  .bg-main.btn {
    border-color: #1C8A34; }
    .bg-main.btn:hover {
      border-color: #156827;
      background: #156827; }

.bg-gray {
  color: #fff;
  background: #333; }

@media (max-width: 576px) {
  form .text-right,
  form .text-left {
    text-align: center !important; }
  form .text-right {
    margin-bottom: 1em; } }

body.home .card-hirano .text-right img {
  width: 200px; }

body.home .card-fujita .text-right img {
  width: 150px; }

body.promise .list-group-item li {
  margin-bottom: 7px;
  margin-left: -20px;
  list-style-type: disc; }
